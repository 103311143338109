.about-container{
    width:80vw;
    margin-left:10vw;
    height:90vh;
    margin-top:2vh;
}
.stats{
    width:80%;
    height:80%;
}

.stats-container{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
@media (max-width: 500px) {
    .stats-container{
        flex-direction:column;
    }
}

a {
    color: lightblue;
    text-decoration: none;
    transition: color 0.3s;
  }

  a:hover {
    color: #21a1f1;
  }