
li {
  text-align: left;
}
h1{
  text-align: center;
}
.icon{
  height:10vh;
  width:10vh;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body{
  background-image: url('./images/background-tile.png');
  background-repeat: repeat;
  background-size: 100px 100px;
  margin:0;
  padding:0;
}
html {
  scroll-behavior: smooth;
  background-color: black;
  color: white;
}
.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Helvetica', sans-serif
}

.App-header a{
  text-decoration: none;
  color: white;
  padding: 1vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
